import { RouteComponentProps, Router } from '@reach/router'
import { withPrefix } from 'gatsby'
import React, { useEffect, useState } from 'react'
import InternalServerError from '../components/InternalServerError'
import BasicLayout from '../layouts/BasicLayout'
import ApplicationList from '../templates/myApps'
import CreateApplication from '../templates/myApps/create'
import EditApplication from '../templates/myApps/edit'
import { getAccessToken, login } from '../utils/auth.utils'
import SubmitApplication from '../templates/myApps/submit'
import { useSelector } from 'react-redux'
import { retrieveFeatureFlags } from '../state/auth'
import { AppState } from '../state/store'
import { hasValidFeatureFlag } from '../utils/general.utils'
import NewApp from '../templates/myApps/new-app'

const RouterPage = (
  props: { component: JSX.Element } & RouteComponentProps
): JSX.Element => {
  return props.component
}

const App: React.FC<RouteComponentProps> = (props: any) => {
  const [showPage, setShowPage] = useState(false)
  const [hasError, setHasError] = useState(false)
  const userFeatureFlags = useSelector((state: AppState) =>
    retrieveFeatureFlags(state)
  )

  const hasServiceAppFeatureFlag = hasValidFeatureFlag(
    userFeatureFlags,
    `${process.env.GATSBY_SERVICE_APP_NEEDS_FEATURE_FLAG}`,
    `${process.env.GATSBY_SERVICE_APP_FEATURE_FLAG}`
  )

  useEffect(() => {
    const checkAuthentication = async (): Promise<void> => {
      const token = await getAccessToken()
      if (token) {
        setShowPage(true)
      } else {
        login()
      }
    }

    checkAuthentication()
  }, [])

  return (
    <>
      {showPage && !hasError ? (
        <BasicLayout>
          {/* @ts-ignore:disable-next-line */}
          <Router className="below-header-container">
            <RouterPage
              path={withPrefix(
                hasServiceAppFeatureFlag
                  ? '/my-apps/new/integration'
                  : '/my-apps/create'
              )}
              component={
                <CreateApplication
                  hasCurrentApplications={
                    props.location.state?.hasCurrentApplications
                  }
                />
              }
            />
            {hasServiceAppFeatureFlag ? (
              <>
                <RouterPage
                  path={withPrefix('/my-apps/new')}
                  component={
                    <NewApp
                      hasCurrentApplications={
                        props.location.state?.hasCurrentApplications
                      }
                    />
                  }
                />
                <RouterPage
                  path={withPrefix('/my-apps/new/service-app')}
                  component={
                    <CreateApplication
                      hasCurrentApplications={
                        props.location.state?.hasCurrentApplications
                      }
                      isServiceApp={true}
                    />
                  }
                />
              </>
            ) : null}
            <RouterPage
              path={withPrefix('/my-apps/:appId')}
              component={
                <EditApplication
                  clientId={props.location.state?.clientId}
                  clientSecret={props.location.state?.clientSecret}
                  setHasError={setHasError}
                  hasBeenSubmitted={props.location.state?.hasBeenSubmitted}
                />
              }
            />
            <RouterPage
              path={withPrefix('/my-apps')}
              component={<ApplicationList setHasError={setHasError} />}
            />
            <RouterPage
              path={withPrefix('/my-apps/:appId/submit')}
              component={
                <SubmitApplication
                  application={props.location.state?.application}
                />
              }
            />
          </Router>
        </BasicLayout>
      ) : null}
      {showPage && hasError ? <InternalServerError /> : null}
    </>
  )
}

export default App
