import { useEffect, useRef, useState } from 'react'
import { isBrowser } from '../utils/general.utils'

export const useCopy = (timeToReset: number): any[] => {
  const timerRef = useRef<any>()
  const [copyClicked, setCopyClicked] = useState(false)

  useEffect(() => {
    return (): void => clearTimeout(timerRef.current)
  }, [])

  const copyToClipboard = (str: string): Promise<void> => {
    const clipboard = window.navigator.clipboard
    return clipboard.writeText(str)
  }

  const handleCopyClick = (textToCopy: string): void => {
    if (isBrowser()) {
      setCopyClicked(true)
      copyToClipboard(textToCopy)

      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }

      timerRef.current = window.setTimeout(
        () => setCopyClicked(false),
        timeToReset
      )
    }
  }

  return [copyClicked, handleCopyClick]
}
