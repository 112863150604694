import React, { ChangeEvent, useEffect, useState } from 'react'
import { Button } from '@momentum-ui/react'
import { useTranslation } from 'react-i18next'
import {
  isImgSizeWithinRange,
  onFileLoad,
} from '../ImageSelector/imageSelector.utils'
import { logError } from '../../services/loggerService'
import InlineMessage from '../InlineErrorMessage'
interface ScreenshotSelectorProps {
  id: string
  shape: string
  imageUrl: string
  type: string
  onChange: Function
  name: string
  handleValidationState: Function
  handleScreenshotState: Function
  handleScreenshotRequirementError: Function
}

const ScreenshotSelector: React.FC<ScreenshotSelectorProps> = ({
  id = '',
  shape = 'rectangle',
  imageUrl,
  type = '',
  onChange,
  name,
  handleValidationState,
  handleScreenshotState,
  handleScreenshotRequirementError,
}) => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string>(imageUrl || '')
  const [hasValidationErr, setHasValidationErr] = useState(false)
  const { t } = useTranslation()

  let isCancelled = false
  useEffect(() => {
    return (): void => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      isCancelled = true
    }
  }, [])

  const selectImage = (file: any): void => {
    setImagePreviewUrl(file)
    const reader = new FileReader()
    const img = new Image()

    reader.onload = (event: any): void => {
      img.src = event.target.result
      onFileLoad(img, event, isCancelled, setImagePreviewUrl)
    }

    reader.onerror = (): void => {
      logError(`Error occurred reading file: ${file.name}`)
    }

    img.onload = (): void => {
      const imgSizeIsValid = isImgSizeWithinRange(
        img.height,
        img.width,
        600,
        900
      )
      handleScreenshotRequirementError(false)
      handleScreenshotState(true, name)
      if (!isCancelled) {
        setHasValidationErr(!imgSizeIsValid)
        if (handleValidationState) {
          handleValidationState(hasValidationErr, name)
        }
        onChange(file)
      }
    }

    reader.readAsDataURL(file)
  }

  return (
    <>
      {!imagePreviewUrl && (
        <div className={'image-options'}>
          <div className={`${'image-option-container'} ${shape}`}>
            <div className="label-text">{type}</div>
            <div className={`${'image-container picker'}`}>
              <div className={'icon-picker-container'}>
                <i className={`icon icon-upload_32 icon`} />
              </div>
              <input
                type="file"
                name={id}
                id={id}
                data-testid="uploadInputScreenshot"
                accept=".png, .jpeg"
                onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                  if (event.target.files) {
                    event.preventDefault()
                    const file = event.target.files[0]
                    selectImage(file)
                  }
                }}
              />
            </div>
            <div className="label-text-upload">
              {t('apphubSubmission.upload')}
            </div>
          </div>
        </div>
      )}

      {imagePreviewUrl && (
        <div className={'image-options'}>
          <div className={`${'image-option-container'} ${shape}`}>
            <div className="label-text">{type}</div>
            <div className={`${'image-container'}`}>
              <div className={'selected-image'}>
                <img
                  id="preview-screenshot"
                  src={
                    imagePreviewUrl === ''
                      ? 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
                      : imagePreviewUrl
                  }
                  alt={t('apphubSubmission.default')}
                  data-testid={id}
                />
              </div>
            </div>
            <Button
              data-testid={`${name}-selector-edit-btn`}
              className="input-action-btn inline-text-btn"
              onClick={(): void => {
                onChange('')
                setImagePreviewUrl('')
                handleScreenshotRequirementError(true)
                handleScreenshotState(false, name)
                if (handleValidationState) {
                  handleValidationState(true, name)
                }
              }}
            >
              {t('actionButtons.edit')}
            </Button>
          </div>
        </div>
      )}

      {hasValidationErr && (
        <InlineMessage
          message={t('apphubSubmission.screenshotSelectorValidationMessage')}
        ></InlineMessage>
      )}
    </>
  )
}

export default ScreenshotSelector
