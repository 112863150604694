import React, { Fragment } from 'react'

interface IInlineErrorMessageProps {
  message?: Array<string> | string
}

const InlineErrorMessage: React.FC<IInlineErrorMessageProps> = ({
  message,
}) => {
  const renderMessage = (messages: Array<string>) => {
    return messages.map((message, key) => (
      <div className="md-input__message" key={key} role="alert">
        {message}
      </div>
    ))
  }

  return (
    <div className="md-input-container md-error">
      <div className="md-input__messages" data-testid="inline-messages">
        {Array.isArray(message) && message.length && (
          <Fragment>{renderMessage(message)}</Fragment>
        )}
        {typeof message === 'string' && (
          <div className="md-input__message" role="alert">
            {message}
          </div>
        )}
      </div>
    </div>
  )
}

export default InlineErrorMessage
