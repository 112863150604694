export const APPLICATION_NOT_SUBMITTED = 'none'
export const APPLICATION_SUBMITTED = 'submitted'

export const DATA_SCHEMA_IDS = [
  {
    id: '5397013b-7920-4ffc-807c-e8a3e0a18f43',
    name: 'serviceApp.virtualAgentSchemaLabel',
  },
  // TODO: Add typeAhead scheme when ID is available. ('serviceApp.typeAheadSchemaLabel')
]
