import React from 'react'
import { useTranslation } from 'react-i18next'
import { dateFormatter } from '../../../utils/general.utils'
import Link from '../../Link'
import { ApplicationTypes } from '../../../interfaces/myAppInterfaces'

interface IApplicationListItemProps {
  name: string
  logo?: string
  id: string
  registrationDate: string[]
  type?: ApplicationTypes
}

const ApplicationListItem: React.FC<IApplicationListItemProps> = ({
  name,
  logo,
  id,
  registrationDate,
  type,
}) => {
  const { t } = useTranslation()
  const formattedDate = dateFormatter(Number(registrationDate[0]))

  return (
    <li className="application-list-item">
      <Link
        className="link-wrapper"
        to={`/my-apps/${id}`}
        state={{ clientId: id }}
        data-cy={`applications-list-item-${id}`}
      >
        <div className="list-item-content">
          <div className="content-left">
            <div className="app-logo-container">
              <div className="app-logo-wrapper">
                <img
                  src={
                    logo ||
                    'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
                  }
                  alt={t('myApps.appLogoAltText')}
                />
              </div>
            </div>
            <div>
              <h3>{name}</h3>
              <p>{`${t('myApps.created')} ${formattedDate}`}</p>
            </div>
          </div>
          <div className="content-right">
            <span className="md-badge md-badge--pastel">
              {t(
                `${
                  type === ApplicationTypes.ServiceApp
                    ? 'serviceApp.serviceApp'
                    : 'integrations.integration'
                }`
              )}
            </span>
          </div>
        </div>
      </Link>
    </li>
  )
}

export default ApplicationListItem
