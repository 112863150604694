export const isImgSizeValid = (
  height: number,
  width: number,
  heightLimit: number,
  widthLimit: number
): boolean => {
  return height === heightLimit && width === widthLimit
}

export const isImgSizeWithinRange = (
  height: number,
  width: number,
  heightLimit: number,
  widthLimit: number
): boolean => {
  return height <= heightLimit && width <= widthLimit
}

export const onFileLoad = (
  image: HTMLImageElement,
  event: any,
  isComponentNotMounted: boolean,
  setImagePreviewState: Function
): void => {
  image.src = event.target.result
  if (!isComponentNotMounted) {
    setImagePreviewState(image.src)
  }
}
